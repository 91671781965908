import revive_payload_client_fVXcT8n4kY from "/home/runner/work/respell/respell/node_modules/.pnpm/nuxt@3.11.2_@azure+storage-blob@12.17.0_encoding@0.1.13__@parcel+watcher@2.4.1_@types+node@20_lay3doh4nxhinpn7twpw3cuyt4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_p5cTOqwMFU from "/home/runner/work/respell/respell/node_modules/.pnpm/nuxt@3.11.2_@azure+storage-blob@12.17.0_encoding@0.1.13__@parcel+watcher@2.4.1_@types+node@20_lay3doh4nxhinpn7twpw3cuyt4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_WPAr5ueanK from "/home/runner/work/respell/respell/node_modules/.pnpm/nuxt@3.11.2_@azure+storage-blob@12.17.0_encoding@0.1.13__@parcel+watcher@2.4.1_@types+node@20_lay3doh4nxhinpn7twpw3cuyt4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import posthog_client_bg08xo0dPF from "/home/runner/work/respell/respell/node_modules/.pnpm/nuxt-posthog@1.5.3_rollup@4.14.2_webpack-sources@3.2.3/node_modules/nuxt-posthog/dist/runtime/plugins/posthog.client.js";
import payload_client_KLeLW8DpjM from "/home/runner/work/respell/respell/node_modules/.pnpm/nuxt@3.11.2_@azure+storage-blob@12.17.0_encoding@0.1.13__@parcel+watcher@2.4.1_@types+node@20_lay3doh4nxhinpn7twpw3cuyt4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_b12i3LpqKZ from "/home/runner/work/respell/respell/node_modules/.pnpm/nuxt@3.11.2_@azure+storage-blob@12.17.0_encoding@0.1.13__@parcel+watcher@2.4.1_@types+node@20_lay3doh4nxhinpn7twpw3cuyt4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_iFptFGwtho from "/home/runner/work/respell/respell/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.14.2_typescript@5.4.3_vue@3.4.21_typescript@5.4.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/respell/respell/apps/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_SzAV2SA6hE from "/home/runner/work/respell/respell/node_modules/.pnpm/nuxt@3.11.2_@azure+storage-blob@12.17.0_encoding@0.1.13__@parcel+watcher@2.4.1_@types+node@20_lay3doh4nxhinpn7twpw3cuyt4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import directives_AA28DnOg44 from "/home/runner/work/respell/respell/node_modules/.pnpm/nuxt-posthog@1.5.3_rollup@4.14.2_webpack-sources@3.2.3/node_modules/nuxt-posthog/dist/runtime/plugins/directives.js";
import plugin_bB43HHaI8s from "/home/runner/work/respell/respell/node_modules/.pnpm/nuxt-csurf@1.6.1_rollup@4.14.2/node_modules/nuxt-csurf/dist/runtime/plugin.mjs";
import slideovers_CqRXMS3OzK from "/home/runner/work/respell/respell/node_modules/.pnpm/@nuxt+ui@2.18.2_axios@1.6.5_change-case@4.1.2_focus-trap@7.5.4_rollup@4.14.2_sortablejs@1.15._ulduaq3xhssjd554zbnqz4iz6i/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_pUeFIBGWaa from "/home/runner/work/respell/respell/node_modules/.pnpm/@nuxt+ui@2.18.2_axios@1.6.5_change-case@4.1.2_focus-trap@7.5.4_rollup@4.14.2_sortablejs@1.15._ulduaq3xhssjd554zbnqz4iz6i/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_eWxwl3d3Cw from "/home/runner/work/respell/respell/node_modules/.pnpm/@nuxt+ui@2.18.2_axios@1.6.5_change-case@4.1.2_focus-trap@7.5.4_rollup@4.14.2_sortablejs@1.15._ulduaq3xhssjd554zbnqz4iz6i/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_QZRwbBjlzy from "/home/runner/work/respell/respell/node_modules/.pnpm/@nuxtjs+color-mode@3.4.2_rollup@4.14.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_GHApuL0yw7 from "/home/runner/work/respell/respell/node_modules/.pnpm/@nuxt+icon@1.3.1_rollup@4.14.2_vite@5.2.2_@types+node@20.12.7_sass@1.75.0_terser@5.26.0__vue@3.4.21_typescript@5.4.3_/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_cslGCHgyVI from "/home/runner/work/respell/respell/node_modules/.pnpm/@sidebase+nuxt-auth@0.7.2_@azure+storage-blob@12.17.0_encoding@0.1.13__encoding@0.1.13_next-a_lj2h6dwoxb4b5ymznfdr7cf5jq/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import plugin_client_qpwKTUfgia from "/home/runner/work/respell/respell/node_modules/.pnpm/nuxt-gtag@0.5.7_rollup@4.14.2/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import chunk_reload_client_eJf9WqfuPa from "/home/runner/work/respell/respell/node_modules/.pnpm/nuxt@3.11.2_@azure+storage-blob@12.17.0_encoding@0.1.13__@parcel+watcher@2.4.1_@types+node@20_lay3doh4nxhinpn7twpw3cuyt4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_1BdlnigI0n from "/home/runner/work/respell/respell/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.0_@pinia+nuxt@0.5.1_rollup@4.14.2_typescript@5.4.3_vue@_3s4eqfugtrvrqugtjirs3wfega/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import filepond_3qnHRCNugt from "/home/runner/work/respell/respell/apps/nuxt/src/plugins/filepond.ts";
import loadGoogleAuthScripts_OqUG037DcI from "/home/runner/work/respell/respell/apps/nuxt/src/plugins/loadGoogleAuthScripts.ts";
import authUnthread_client_wQjIaLV3NI from "/home/runner/work/respell/respell/apps/nuxt/src/plugins/authUnthread.client.ts";
import casl_D1YIVgqED7 from "/home/runner/work/respell/respell/apps/nuxt/src/plugins/casl.ts";
import sentry_client_KAXFuL2wum from "/home/runner/work/respell/respell/apps/nuxt/src/plugins/sentry.client.ts";
export default [
  revive_payload_client_fVXcT8n4kY,
  unhead_p5cTOqwMFU,
  router_WPAr5ueanK,
  posthog_client_bg08xo0dPF,
  payload_client_KLeLW8DpjM,
  check_outdated_build_client_b12i3LpqKZ,
  plugin_vue3_iFptFGwtho,
  components_plugin_KR1HBZs4kY,
  prefetch_client_SzAV2SA6hE,
  directives_AA28DnOg44,
  plugin_bB43HHaI8s,
  slideovers_CqRXMS3OzK,
  modals_pUeFIBGWaa,
  colors_eWxwl3d3Cw,
  plugin_client_QZRwbBjlzy,
  plugin_GHApuL0yw7,
  plugin_cslGCHgyVI,
  plugin_client_qpwKTUfgia,
  chunk_reload_client_eJf9WqfuPa,
  plugin_1BdlnigI0n,
  filepond_3qnHRCNugt,
  loadGoogleAuthScripts_OqUG037DcI,
  authUnthread_client_wQjIaLV3NI,
  casl_D1YIVgqED7,
  sentry_client_KAXFuL2wum
]