import validate from "/home/runner/work/respell/respell/node_modules/.pnpm/nuxt@3.11.2_@azure+storage-blob@12.17.0_encoding@0.1.13__@parcel+watcher@2.4.1_@types+node@20_lay3doh4nxhinpn7twpw3cuyt4/node_modules/nuxt/dist/pages/runtime/validate.js";
import back_45to_45dash_45global from "/home/runner/work/respell/respell/apps/nuxt/src/middleware/backToDash.global.ts";
import explore_45page_45global from "/home/runner/work/respell/respell/apps/nuxt/src/middleware/explorePage.global.ts";
import manifest_45route_45rule from "/home/runner/work/respell/respell/node_modules/.pnpm/nuxt@3.11.2_@azure+storage-blob@12.17.0_encoding@0.1.13__@parcel+watcher@2.4.1_@types+node@20_lay3doh4nxhinpn7twpw3cuyt4/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  back_45to_45dash_45global,
  explore_45page_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/home/runner/work/respell/respell/node_modules/.pnpm/@sidebase+nuxt-auth@0.7.2_@azure+storage-blob@12.17.0_encoding@0.1.13__encoding@0.1.13_next-a_lj2h6dwoxb4b5ymznfdr7cf5jq/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}