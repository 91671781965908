import type { DropdownItem } from '#ui/types';
import { createId } from '@paralleldrive/cuid2';
import type {
  Edge,
  GraphInputType,
  IntegrationLink,
  Note,
  Step,
  Trigger,
} from '@respell/database';
import { triggers } from '@respell/integrations';
import type { TriggerDefinition } from '@respell/integrations/definition';
import definitions from '@respell/steps';
import type { StepDefinition } from '@respell/steps/types';
import type {
  Condition,
  Dictionary,
  Json,
  ReferenceCondition,
  TemplatedFunction,
  Variable,
} from '@respell/utils';
import type { GraphEdge, GraphNode } from '@vue-flow/core';
import { MarkerType, Position, useVueFlow } from '@vue-flow/core';
import { useChangeCase } from '@vueuse/integrations/useChangeCase';
import { useFuse } from '@vueuse/integrations/useFuse';
import { acceptHMRUpdate, defineStore } from 'pinia';
import ConfirmModal from '~/components/modals/ConfirmModal.vue';
import type { Graph } from '~/stores/spells';
import { validateOptions } from '~/util/validation';
import { useSpellsStore } from './spells';

export const useCanvasStore = defineStore('canvas', {
  state: () => ({
    graph: undefined as Graph | undefined,
    trigger: null as Trigger | null,
    isLoading: false,
    isSaving: false,
    elements: [] as Array<GraphNode | GraphEdge | any>,
    isShowingPanel: false,
    typeFilter: allType as StepType,
    variableState: null as 'adding-input' | 'adding-output' | null,
    variable: null as Variable | null,
    searchQuery: null as string | null,
    selectedPath: null as Condition | null,
    errors: {} as Record<string, any[]>,
    configTab: 0,
    // PROMPT WIZARD
    prompt: null as string | null,
    promptContext: {} as Record<string, string>,
    needsContext: false,
  }),
  getters: {
    stepOptions(state) {
      const steps = Object.entries(definitions)
        .filter(
          ([_, step]) =>
            (state.typeFilter.key === 'all' ||
              step.category === state.typeFilter.key) &&
            step.key !== 'start',
        )
        .map(([key, step]) => ({
          key,
          ...step,
        }));

      const { results } = useFuse(state.searchQuery ?? '', steps, {
        fuseOptions: {
          keys: ['name', 'services', 'description'],
          threshold: 0.3,
        },
        matchAllWhenSearchEmpty: true,
      });

      return results.value.reduce(
        (acc, { item }) => {
          acc[item.key] = item;
          return acc;
        },
        {} as Record<string, (typeof definitions)[keyof typeof definitions]>,
      );
    },
    stepsByService() {
      return Object.values(this.stepOptions ?? {}).reduce((acc, step) => {
        const service = step.services?.[0];
        if (service) {
          if (!acc[service]) {
            acc[service] = [];
          }
          acc[service].push(step);
        }
        return acc;
      }, {});
    },
    triggerReady(state): boolean {
      return !!state.trigger?.service && !!state.trigger?.event;
    },
    triggerDefinition(state): TriggerDefinition | null {
      return !!state.trigger?.service && !!state.trigger?.event
        ? triggers[state.trigger.service][state.trigger.event]
        : null;
    },
    specialNodeTypes(state): Record<string, string> {
      return {
        trigger: 'trigger',
        start: state.graph?.inputType === 'trigger' ? 'trigger' : 'start',
        display: 'display',
        condition: 'condition',
        review: 'review',
      };
    },
    canPublish(state): boolean {
      return (
        state.elements.some((el) => el.type === 'start') ||
        state.elements.some((el) => el.type === 'trigger')
      );
    },
    startType(state): GraphInputType | null | undefined {
      return state.elements.some((el) => el.type === 'add-start')
        ? null
        : state.trigger
          ? 'trigger'
          : 'manual';
    },
    startInputs(state): Record<string, Variable> {
      return Object.fromEntries(
        Object.entries(state.graph?.inputs ?? {}).filter(
          ([, input]) => !input.metadata?.forReview,
        ),
      );
    },
  },
  actions: {
    loadCanvas() {
      this.$reset();
      this.isLoading = true;

      const { inEditor } = useRouteName();
      const spellStore = useSpellsStore();

      this.graph = inEditor.value
        ? spellStore.draftGraph
        : spellStore.liveGraph;
      this.trigger = this.graph?.trigger;
      this.graph?.steps.forEach((step) => this.importStep(step));
      this.graph?.edges.forEach((edge) => this.importEdge(edge));
      this.graph?.notes?.forEach((note) => this.importNote(note));

      // Add Add-Start node if canvas is blank
      if (!this.elements.length && inEditor.value) {
        this.elements.push({
          id: createId(),
          type: 'add-start',
          position: { x: 0, y: 0 },
          draggable: false,
        });
      }

      this.isLoading = false;
      return this.elements;
    },
    async saveCanvas() {
      if (this.isLoading || this.isSaving || !this.graph) return;

      this.isSaving = true;

      const steps: Step[] = [];
      const edges: Edge[] = [];
      const notes: Note[] = [];

      this.elements.forEach((element: GraphNode | GraphEdge) => {
        if ('source' in element) {
          const exportedEdge = this.exportEdge(element);
          if (exportedEdge) edges.push(exportedEdge);
        } else if (element.type === 'note') {
          const exportedNote = this.exportNote(element);
          if (exportedNote) notes.push(exportedNote);
        } else {
          const exportedStep = this.exportStep(element);
          if (exportedStep) steps.push(exportedStep);
        }
      });

      try {
        const spellStore = useSpellsStore();

        const newGraph = await $api<Graph>(
          `/api/spells/${this.graph.spellId}/graphs/${this.graph.id}/save`,
          {
            method: 'patch',
            body: {
              steps,
              edges,
              notes,
              inputs: this.graph.inputs,
              outputs: this.graph.outputs,
              trigger: this.triggerReady ? this.trigger : undefined,
            },
          },
        );

        this.graph = newGraph;
        spellStore.draftGraph = this.graph;
        clearNuxtData(`spell/${this.graph.spellId}`);
        if (newGraph.trigger) {
          this.trigger = newGraph.trigger;
        }
      } catch (error) {
        console.error('Failed to save canvas:', error);
        this.loadCanvas();
      } finally {
        this.isSaving = false;
      }

      return this.graph;
    },
    async publishSpell() {
      const spellStore = useSpellsStore();
      // Save one last time before publishing
      await this.saveCanvas();

      // Publish
      const { spellId } = await $api<Graph>(
        `/api/spells/${this.graph?.spellId}/publish`,
        {
          method: 'patch',
        },
      );

      await spellStore.refresh();

      await navigateTo({
        name: this.trigger ? 'spell.history' : 'spell.run',
        params: { spellId },
        replace: true,
      });

      // Reset canvas
      this.loadCanvas();

      return this.graph;
    },
    async restoreGraph(graphId: string) {
      const spellStore = useSpellsStore();
      const { draftGraph } = storeToRefs(spellStore);

      this.isLoading = true;
      try {
        await this.saveCanvas();

        await $api(
          `/api/spells/${this.graph?.spellId}/graphs/${graphId}/restore`,
          { method: 'patch' },
        );

        await spellStore.refresh();
      } catch (error) {
        console.error('Failed to restore graph:', error);
      } finally {
        await until(draftGraph).changed();
        this.loadCanvas();
        this.isLoading = false;
      }

      return this.graph;
    },
    importStep(step: Step) {
      const graphNode = {
        id: step.id,
        label: step.label,
        type: this.specialNodeTypes[step.key] || 'step',
        position: {
          x: step.x,
          y: step.y,
        },
        data: {
          slug: step.slug,
          key: step.key,
          options: step.options,
          context: step.context,
          integrations: step.integrations ?? [],
        },
      };

      this.elements.push(graphNode);
    },
    importNote(note: Note) {
      const graphNote = {
        id: note.id,
        type: 'note',
        position: {
          x: note.x,
          y: note.y,
        },
        dimensions: {
          width: note.width,
          height: note.height,
        },
        data: {
          content: note.content,
          toolbarPosition: Position.Top,
        },
      };

      this.elements.push(graphNote);
    },
    importEdge(edge: Edge) {
      const graphEdge = {
        id: edge.id,
        source: edge.fromId,
        target: edge.toId,
        sourceHandle: edge.conditionPath,
        type: edge.conditionPath ? 'condition' : 'step',
        markerEnd,
      };

      this.elements.push(graphEdge);
    },
    exportStep(graphNode: GraphNode) {
      if (['add-start', 'add-step'].includes(graphNode.type)) return;
      const { id, label, position, data } = graphNode;
      const step = {
        id,
        label,
        graphId: this.graph?.id,
        x: position.x,
        y: position.y,
        ...data,
      };
      return step;
    },
    exportNote(graphNote: GraphNode) {
      const { id, position, dimensions, data } = graphNote;
      const note = {
        id,
        graphId: this.graph?.id,
        x: position.x,
        y: position.y,
        width: dimensions.width,
        height: dimensions.height,
        content: data.content,
      };
      return note;
    },
    exportEdge(graphEdge: GraphEdge) {
      if (graphEdge.type === 'add-step') return;
      const edge = {
        id: graphEdge.id,
        graphId: this.graph?.id,
        fromId: graphEdge.source,
        toId: graphEdge.target,
        conditionPath: this.elements.some(
          (el) => el.data?.slug === graphEdge.sourceHandle,
        )
          ? graphEdge.sourceHandle
          : undefined,
      };

      return edge;
    },
    toggleAddStep({
      source,
      sourceHandle,
      target,
    }: {
      source: string;
      sourceHandle?: string;
      target?: string;
    }) {
      const { getConnectedEdges } = useVueFlow({ id: 'editor' });

      const sourceNode = this.elements.find((el) => el.id === source);
      if (!sourceNode) return;
      const sourceHandleY =
        sourceNode.handleBounds.source.find((s) => s.id === sourceHandle)?.y ??
        0;

      //Create temporary add step node and edge based on source node position
      const addStepNode = {
        id: createId(),
        type: 'add-step',
        position: {
          x: sourceNode?.computedPosition?.x + 400, // TODO: calculate based on source handle position
          y: sourceNode?.computedPosition?.y + sourceHandleY,
        },
      };

      this.elements.push(addStepNode);
      this.addEdge({
        source,
        target: addStepNode.id,
        sourceHandle,
        type: 'add-step',
      });

      if (target) {
        const targetNode = this.elements.find((el) => el.id === target);
        const existingEdge = getConnectedEdges(target).find(
          (edge) => edge.source === source,
        );
        if (!targetNode || !existingEdge) return;
        //Remove existing edge between source and target
        this.elements = this.elements.filter((el) => el.id !== existingEdge.id);
        //Move target node to the right
        targetNode.computedPosition.x += 200; // TODO: calculate based on target handle position
        //Create edge between new add step node and target node

        this.addEdge({
          source: addStepNode.id,
          target,
          type: 'add-step',
        });
      }
    },
    hydrateStartStep(key: string) {
      const addStartStep = this.elements.find((el) => el.type === 'add-start');
      addStartStep.draggable = true;
      this.formatStep({ key, node: addStartStep });
    },
    addStep(key: string, position: { x: number; y: number }) {
      const newNode = this.formatStep({ key, position });

      this.elements.push(newNode);
      this.resetSidebar();
      return newNode;
    },
    formatStep({
      key,
      position,
      node,
    }: {
      key: string;
      position?: { x: number; y: number };
      node?: GraphNode;
    }) {
      // Reset sidebar filter
      this.typeFilter = allType;

      const newNode = ref();

      // Clone to prevent reactive binding of step definition to step instance
      const stepType = useCloned(definitions[key === 'trigger' ? 'start' : key])
        .cloned.value;
      const nodeType = this.specialNodeTypes[key] || 'step';
      // Sets each option to its default value
      const defaultOptions = Object.keys(stepType.options ?? {}).reduce(
        (acc, key) => {
          acc[key] = stepType.options[key].value;
          return acc;
        },
        {},
      );

      // Add linked accounts to step if it has services
      let integrations: IntegrationLink[] = [];
      if (stepType.services?.length) {
        const integrationStore = useIntegrationStore();
        integrations = integrationStore.linkedAccounts.filter((account) =>
          stepType.services.includes(account.service),
        );
      }

      const data = {
        slug: this.generateSlug(stepType.key),
        key: stepType.key,
        options: defaultOptions,
        context: {},
        integrations,
      };

      if (node) {
        node.type = nodeType;
        node.data = data;
        newNode.value = node;
      } else {
        newNode.value = {
          id: createId(),
          type: nodeType,
          position,
          data,
        };
      }

      return newNode.value;
    },
    hydrateStep(key: string) {
      const { getConnectedEdges } = useVueFlow({ id: 'editor' });
      const { addingStep } = useSelectedStep();
      if (addingStep.value) {
        const newStep = this.formatStep({ key, node: addingStep.value });
        const connectedEdges = getConnectedEdges(newStep.id);
        connectedEdges.forEach((edge) => {
          if (edge.sourceNode.data.key === 'condition') {
            this.addEdge({
              source: edge.sourceNode.id,
              sourceHandle: edge.sourceHandle,
              target: newStep.id,
            });
            this.elements = this.elements.filter((el) => el.id !== edge.id);
          } else {
            edge.type = 'step';
          }
        });
        return newStep;
      }
    },
    dropStep(key: string, position: { x: number; y: number }) {
      const { selectedStep } = useSelectedStep();
      // This ignored drop events in the config panel (dragging)
      if (selectedStep.value) return;

      const { onNodesInitialized, updateNode } = useVueFlow({ id: 'editor' });
      const newNode = this.formatStep({ key, position });

      /**
       * Align node position after drop, so it's centered to the mouse
       *
       * We can hook into events even in a callback, and we can remove the event listener after it's been called.
       */
      const { off } = onNodesInitialized(() => {
        updateNode(newNode.id, (node) => ({
          position: {
            x: node.position.x - node.dimensions.width / 2,
            y: node.position.y - node.dimensions.height / 2,
          },
        }));

        off();
      });
      this.elements.push(newNode);
      this.resetSidebar();
    },
    addEdge(props: {
      source: string;
      target: string;
      sourceHandle?: string | null;
      type?: 'condition' | 'step' | 'add-step';
    }) {
      let conditionPath = props.sourceHandle;

      // If a condition path handle is being connected to a step,
      // set the condition path to the target step slug
      if (props.type !== 'add-step') {
        if (conditionPath && !conditionPath.includes('_handle-')) {
          const sourceNode = this.elements.find((el) => el.id === props.source);
          const targetNode = this.elements.find((el) => el.id === props.target);

          conditionPath = targetNode.data.slug;

          sourceNode.data.options.conditions.find(
            (c: Condition) => c.path === props.sourceHandle,
          ).path = conditionPath;
        } else {
          conditionPath = null;
        }
      }

      const newEdge = {
        id: createId(),
        source: props.source,
        target: props.target,
        sourceHandle: conditionPath,
        type: props.type ? props.type : conditionPath ? 'condition' : 'step',
        markerEnd,
      };

      this.elements.push(newEdge);
    },
    removeNote(id: string) {
      const modal = useModal();

      const handleDelete = () => {
        this.elements = this.elements.filter((el) => el.id !== id);
      };

      modal.open(ConfirmModal, {
        action: 'delete',
        type: 'note',
        message: 'The contents of this note will be lost',
        isDangerous: true,
        onConfirm() {
          handleDelete();
          modal.close();
        },
      });
    },
    removeStep(slug: string) {
      const hasErrors = Object.keys(this.errors?.[slug] ?? {}).length > 0;

      const handleDelete = () => {
        delete this.errors?.[slug];
        this.elements = this.elements.filter((el) => el.data?.slug !== slug);
      };

      if (hasErrors) {
        handleDelete();
      } else {
        const modal = useModal();

        modal.open(ConfirmModal, {
          action: 'delete',
          type: 'step',
          message: 'All options for this step will be lost.',
          isDangerous: true,
          onConfirm() {
            handleDelete();
            modal.close();
          },
        });
      }
    },
    getPreviousNodes(nodeId: string): GraphNode[] {
      if (!nodeId) return [];

      const { getIncomers } = useVueFlow({ id: 'editor' });
      const visited = new Set<string>();
      const previousNodes = new Set<GraphNode>();

      const visitNode = (id: string) => {
        if (visited.has(id)) return;
        visited.add(id);

        const incomers = getIncomers(id);
        incomers.forEach((node) => {
          previousNodes.add(node);
          visitNode(node.id);
        });
      };

      visitNode(nodeId);
      return Array.from(previousNodes);
    },
    generateSlug(key: string) {
      const existingSlugs = this.elements
        .filter((el) => el.data?.key === key)
        .map((el) => el.data?.slug);

      let maxCount = 0;
      existingSlugs.forEach((slug) => {
        if (slug === key) {
          maxCount = Math.max(maxCount, 1);
        } else {
          const match = slug.match(new RegExp(`^${key}_(\\d+)$`));
          if (match) {
            const count = parseInt(match[1], 10);
            if (count > maxCount) {
              maxCount = count;
            }
          }
        }
      });

      return maxCount === 0 ? key : `${key}_${maxCount + 1}`;
    },
    generateKey(name: string, type: 'input' | 'output') {
      const key = useChangeCase(name, 'snakeCase').value;
      const existingKeys = Object.keys(this.graph?.[`${type}s`] ?? {});
      const count = existingKeys.filter((el) => el.startsWith(key)).length;
      return count === 0 ? key : `${key}_${count + 1}`;
    },
    generateKeyedList(list: { name: string; [key: string]: any }[]) {
      const keyCount: Record<string, number> = {};

      return list.map((item) => {
        const baseKey = useChangeCase(item.name, 'snakeCase').value;
        keyCount[baseKey] = (keyCount[baseKey] || 0) + 1;
        const key =
          keyCount[baseKey] === 1 ? baseKey : `${baseKey}_${keyCount[baseKey]}`;

        return {
          ...item,
          key,
        };
      });
    },
    generatePathKey(conditions: Condition[]) {
      let maxNumber = 0;

      conditions.forEach((condition) => {
        const match = condition.path.match(/^path_(\d+)$/);
        if (match) {
          const number = parseInt(match[1], 10);
          if (number > maxNumber) {
            maxNumber = number;
          }
        }
      });

      return `path_${maxNumber + 1}`;
    },
    generatePathLabel(path: string) {
      if (path.includes('path')) {
        return useChangeCase(path, 'capitalCase').value;
      } else {
        const targetNode = this.elements.find((el) => el.data?.slug === path);
        return targetNode.label ?? definitions[targetNode.data.key].name;
      }
    },
    async addVariable(
      variable: Variable,
      type: 'input' | 'output',
      isUpdate = false,
      step?: Step,
    ) {
      if (!this.graph) return;

      const { selectedStep } = useSelectedStep();
      const stepType = selectedStep.value?.type;

      if (!step && ['review', 'display'].includes(stepType) && !isUpdate) {
        selectedStep.value?.data.options[
          stepType === 'review' ? `${type}s` : 'references'
        ].push(variable.key);
      }

      // Determine the new order value
      if (!isUpdate) {
        const existingVariables = Object.values(this.graph[`${type}s`]);
        const maxOrder = Math.max(
          -1,
          ...existingVariables.map((v) => v.order ?? -1),
        );

        if (maxOrder >= 0) {
          // Some variables have order set, use max + 1
          variable.order = maxOrder + 1;
        } else {
          // No variables have order set, use length
          variable.order = existingVariables.length;
        }
      }

      this.graph[`${type}s`] = {
        ...this.graph[`${type}s`],
        [variable.key]: variable,
      };

      this.variableState = null;
      this.variable = null;
    },
    editVariable(variable: Variable) {
      const { selectedStep } = useSelectedStep();
      if (selectedStep.value?.type === 'review') {
        this.variableState = selectedStep.value?.data.options.inputs.includes(
          variable.key,
        )
          ? 'adding-input'
          : 'adding-output';
      } else if (selectedStep.value?.type === 'start') {
        this.variableState = 'adding-input';
      } else {
        this.variableState = 'adding-output';
      }

      this.variable = variable;
    },
    removeVariable(key: string, type: 'input' | 'output') {
      if (!this.graph) return;
      const { selectedStep } = useSelectedStep();
      const stepType: string = selectedStep.value?.type;

      if (stepType && ['review', 'display'].includes(stepType)) {
        const optionKey = stepType === 'review' ? `${type}s` : 'references';
        selectedStep.value!.data.options[optionKey] =
          selectedStep.value!.data.options[optionKey].filter(
            (el: any) => el !== key,
          );
      }

      delete this.graph[`${type}s`][key];
    },
    fetchReferences(id: string | undefined) {
      if (!id) return;
      const node = this.elements.find((el) => el.id === id);
      if (!node) return;

      const outputReferences =
        node.type === 'review'
          ? node.data.options.outputs
          : node.data.options.references;
      const displayOutputs = (outputReferences ?? []).reduce(
        (result: Record<string, string>, key: string) => {
          if (key in this.graph?.outputs) {
            result[key] = this.graph?.outputs[key];
          }
          return result;
        },
        {},
      );

      const inputReferences = node.data.options.inputs;
      const displayInputs = (inputReferences ?? []).reduce(
        (result: Record<string, string>, key: string) => {
          if (key in this.graph?.inputs) {
            result[key] = this.graph?.inputs[key];
          }
          return result;
        },
        {},
      );

      return { outputs: displayOutputs, inputs: displayInputs };
    },
    fetchStepOutputs(stepType: StepDefinition, stepId: string) {
      return stepType.key === 'start'
        ? this.startInputs
        : stepType.key === 'trigger' && this.triggerDefinition
          ? this.triggerDefinition.outputs
          : stepType.key === 'review'
            ? this.fetchReferences(stepId)?.inputs
            : stepType.key === 'display'
              ? this.fetchReferences(stepId)?.outputs
              : stepType.outputs || {};
    },
    validateStep(slug: string, key: string, options: Json) {
      const { inEditor } = useRouteName();

      let stepErrors: { path: string | null; message: string }[] = [];

      const step = this.elements.find((el) => el.data?.slug === slug);
      const stepDefinition: StepDefinition = definitions[key];

      if (!this.specialNodeTypes[key]) {
        const validatedOptions = validateOptions(
          stepDefinition.options,
          options,
        );

        if (!validatedOptions.success) {
          stepErrors = validatedOptions.error?.errors.map((error) => {
            return {
              path: error.path.join('.'),
              message: error.message,
            };
          });
        }

        if (
          stepDefinition.services?.length &&
          !step?.data.integrations?.length
        ) {
          stepErrors.push({
            path: 'Account',
            message:
              useChangeCase(stepDefinition.services.join(', '), 'capitalCase')
                .value + ' account is not connected',
          });
        }
      } else if (key === 'trigger' && !this.trigger?.integration) {
        stepErrors.push({
          path: 'Account',
          message:
            useChangeCase(this.trigger?.service ?? 'Trigger', 'capitalCase')
              .value + ' account is not connected',
        });
      } else if (key === 'review') {
        if (!options?.inputs?.length && !options?.outputs?.length) {
          stepErrors.push({
            path: 'Warning',
            message: 'At least one input or output is required',
          });
        }
      }

      if (inEditor.value && this.elements.length > 1) {
        const { getConnectedEdges } = useVueFlow({ id: 'editor' });

        if (!getConnectedEdges(step.id).length) {
          stepErrors.push({
            path: 'Warning',
            message: 'This step is disconnected',
          });
        }
      }

      this.errors[slug] = stepErrors;
      return stepErrors;
    },
    updateStartType(key: string) {
      const { selectedStep } = useSelectedStep();
      if (!selectedStep.value) return;
      if (selectedStep.value.type === 'add-start') {
        this.hydrateStartStep(key);
      }

      // If changing to start, clear the trigger
      if (key === 'start') {
        this.trigger = null;
      } else {
        this.graph.inputs = {};
        this.trigger = {} as Trigger;
      }
      selectedStep.value.type = key;
    },
    parseVariableId(id: string) {
      // Extract the step_slug and step_key from the id
      const match = id.match(variableRegex);

      const [_, stepSlug, stepKey, fieldKey, , macroKey] = match ?? [];

      if (stepSlug === '_internal_variables') {
        const keyNameMap: Dictionary<string> = {
          name: 'Spell Name',
          version: 'Spell Version',
          createdAt: 'Created At',
          currentTime: 'Current Time',
        };
        const keyTypeMap: Dictionary<string> = {
          name: 'text/plain',
          version: 'text/plain',
          createdAt: 'datetime',
          author: 'text/plain',
          currentTime: 'datetime',
        };
        return {
          variable: {
            key: fieldKey,
            name: keyNameMap[stepKey],
            type: keyTypeMap[stepKey],
            listDepth: 0,
            isOptional: false,
            value: null,
          },
          fieldKey,
          macroKey,
          linkedAccount: null,
          options: [
            {
              key: fieldKey,
              name: keyNameMap[stepKey],
              type: 'text/plain',
              listDepth: 0,
              isOptional: false,
              value: null,
            },
          ],
          stepSlug,
          stepKey,
        };
      }

      let variable = null;
      let linkedAccount = null;
      let options = null;
      let context = null;

      // Find the source node based on the step_slug
      const sourceNode = stepSlug
        ? this.elements.find((el) => el.data?.slug === stepSlug)
        : null;

      if (match && sourceNode) {
        if (sourceNode.type === 'trigger' && this.triggerDefinition) {
          variable = this.triggerDefinition.outputs[stepKey];
          linkedAccount = this.trigger?.integration;
          context = variable?.description;
        } else if (sourceNode.type === 'start') {
          variable = this.graph.inputs[stepKey];
          context = variable?.description;
        } else if (sourceNode.type === 'display') {
          variable = this.graph.outputs[stepKey];
          context = variable?.description;
        } else if (sourceNode.type === 'review') {
          variable = this.graph.outputs[stepKey] ?? this.graph.inputs[stepKey];
          context = variable?.description;
        } else {
          variable = definitions[sourceNode.data.key].outputs[stepKey];
          linkedAccount = sourceNode.data.integrations?.[0];
          context = sourceNode.data.context?.[stepKey ?? ''];
        }

        options =
          sourceNode.type === 'trigger'
            ? this.trigger?.options
            : sourceNode.data.options;
      }

      if (sourceNode.data.key === 'pick_item' && options.value) {
        const {
          variable: pickItemVariable,
          linkedAccount: pickItemLinkedAccount,
          options: pickItemOptions,
        } = this.parseVariableId(options.value);
        variable = {
          ...variable,
          type: pickItemVariable.type,
          metadata: pickItemVariable.metadata,
          listDepth: pickItemVariable.listDepth - 1,
        };
        linkedAccount = pickItemLinkedAccount;
        options = pickItemOptions;
      }

      if (!variable) {
        variable = {
          name: 'Missing variable',
          key: 'missing',
          listDepth: 0,
          isOptional: false,
          value: null,
          type: 'missing',
        };
      }

      return {
        variable,
        context,
        fieldKey,
        macroKey,
        linkedAccount,
        options,
        stepSlug,
        stepKey,
      };
    },
    linkStep(linkedAccount: IntegrationLink, stepId: string) {
      const node = this.elements.find((el) => el.id === stepId);
      node.data.integrations.push(linkedAccount);
      this.validateStep(node.data.slug, node.data.key, node.data.options);
      return node.data.integrations;
    },
    linkTrigger(linkedAccount: IntegrationLink) {
      this.trigger.integration = linkedAccount;
    },
    unLinkStep(linkId: string, stepId: string) {
      const node = this.elements.find((el) => el.id === stepId);
      if (node) {
        node.data.integrations = node.data.integrations.filter(
          (account) => account.id !== linkId,
        );
      }
      this.validateStep(node.data.slug, node.data.key, node.data.options);
    },
    unlinkTrigger() {
      if (this.trigger) {
        this.trigger.integration = null;
      }
    },
    evaluateCondition(
      condition: ReferenceCondition,
      optionValue: any,
    ): boolean {
      switch (condition.type) {
        case 'equals':
          return optionValue === condition.value;
        case 'not':
          return optionValue !== condition.value;
        case 'in':
          return (
            Array.isArray(condition.value) &&
            condition.value.includes(optionValue)
          );
        case 'notIn':
          return (
            Array.isArray(condition.value) &&
            !condition.value.includes(optionValue)
          );
        case 'lt':
          return optionValue < condition.value;
        case 'lte':
          return optionValue <= condition.value;
        case 'gt':
          return optionValue > condition.value;
        case 'gte':
          return optionValue >= condition.value;
        case 'contains':
          return optionValue.includes(condition.value);
        case 'doesNotContain':
          return !optionValue.includes(condition.value);
        case 'search':
          return new RegExp(condition.value, 'i').test(optionValue);
        case 'startsWith':
          return optionValue.startsWith(condition.value);
        case 'endsWith':
          return optionValue.endsWith(condition.value);
        default:
          return false;
      }
    },
    resetSidebar() {
      const { addingStep } = useSelectedStep();
      if (addingStep.value) {
        const { removeSelectedNodes } = useVueFlow({ id: 'editor' });
        removeSelectedNodes([]);
      }
      this.searchQuery = null;
      this.isShowingPanel = false;
      this.typeFilter = allType;
    },
    async fetchContext({
      options,
      linkedAccountId,
      reference,
      isReady,
      query,
    }: {
      options: Record<string, any>;
      linkedAccountId?: string;
      reference: TemplatedFunction;
      isReady: boolean;
      query?: string;
    }) {
      if (!reference || !isReady) return null;

      let contextUpdate = null;
      try {
        if (linkedAccountId) {
          const integrationStore = useIntegrationStore();
          contextUpdate = await integrationStore.fetchContext({
            options,
            linkedAccountId,
            reference,
            query,
          });
        } else {
          const workspaceStore = useWorkspaceStore();
          const { workspaceId } = storeToRefs(workspaceStore);

          contextUpdate = await $api<Dictionary<Json>>(
            `/api/workspaces/${workspaceId.value}/context`,
            {
              method: 'POST',
              body: {
                reference,
                options: { ...options, query },
              },
            },
          );
        }
      } catch (error) {
        console.error('Error loading context', error);
      }
      return contextUpdate;
    },
    addContext({
      step,
      variable,
      context,
    }: {
      step: string;
      variable: Variable;
      context: string;
    }) {
      const sourceStep = this.elements.find((el) => el.data?.slug === step);
      if (!sourceStep || !variable.key) return;
      if (sourceStep.type === 'start') {
        this.graph.inputs[variable.key] = {
          ...this.graph.inputs[variable.key],
          description: context,
        };
      } else if (sourceStep.type === 'display') {
        this.graph.outputs[variable.key] = {
          ...this.graph.outputs[variable.key],
          description: context,
        };
      } else {
        sourceStep.data.context = {
          ...sourceStep.data.context,
          [variable.key]: context,
        };
      }
      this.loadPromptContext();
    },
    loadPromptContext(prompt?: string) {
      this.prompt = prompt ?? this.prompt;
      if (!this.prompt) return;
      const variables = this.prompt.match(globalVariableRegex);
      const promptContext = {};
      if (variables?.length) {
        variables.forEach((variable) => {
          const { context } = this.parseVariableId(variable);
          const key = variable.slice(2, -2);
          promptContext[key] = context ?? null;
        });
      }
      this.promptContext = promptContext;
      this.needsContext = Object.values(promptContext).some(
        (context) => !context?.length,
      );
    },
    showVariableContext(stepId: string, variable: Variable) {
      const step = this.elements.find((el) => el.id === stepId);
      if (!step) return;

      const { selectedStep } = useSelectedStep();

      if (!selectedStep.value) {
        const { addSelectedNodes } = useVueFlow({
          id: 'editor',
        });
        addSelectedNodes([step]);
      }
      setTimeout(() => {
        if (['start', 'display', 'review'].includes(step.type)) {
          this.editVariable(variable);
        } else {
          this.configTab = 1;
        }
      }, 5);
    },
    addNote() {
      const { addSelectedNodes } = useVueFlow({
        id: 'editor',
      });

      const newNote = {
        id: createId(),
        type: 'note',
        position: { x: 0, y: 0 },
        draggable: true,
        data: {
          content: '',
        },
      };

      this.elements.push(newNote);
      setTimeout(() => {
        addSelectedNodes([newNote]);
      }, 10);
    },
  },
});

export interface KeyableDropdownItem extends DropdownItem {
  key: string;
  name: string;
}

export type StepType = {
  name: string;
  key: string;
  icon: string;
  children?: StepType[];
};

export const triggerType = {
  ...definitions.start,
  name: 'Trigger',
  category: 'flow',
  key: 'trigger',
  icon: 'i-ph-lightning-fill',
};

export const addStartType = {
  name: 'Select start',
  category: null,
  key: 'add-start',
  icon: 'i-ph-play-circle',
};

export const allType = {
  name: 'All',
  key: 'all',
  icon: 'i-ph-cube-bold',
};

const markerEnd = {
  type: MarkerType.Arrow,
  color: '#5344e5',
  width: 15,
  height: 15,
};

export const variableRegex =
  /\{\{([\w]+)\.([\w]+)(?:\.([\w]+))?(->([\w]+))?\}\}/;
export const globalVariableRegex = new RegExp(variableRegex, 'g');

export const localContextSteps = [
  'get_file',
  'add_file_text',
  'add_file',
  'search_datasources',
  'generate_text',
];

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCanvasStore, import.meta.hot));
}
