import { StepDefinition } from '../../types';

export default {
  key: 'pick_item',
  name: 'Pick From List',
  description: 'Picks an item from a list using AI instructions.',
  icon: 'i-respell-classify-text',
  category: 'text',
  options: {
    value: {
      key: 'value',
      name: 'Input',
      isOptional: false,
      type: 'object',
      listDepth: 0,
      value: null,
    },
    instructions: {
      key: 'instructions',
      name: 'Instructions',
      description: 'Instructions for selecting an item from the list.',
      metadata: {
        hint: 'Pick the contact most likely to respond.',
      },
      isOptional: false,
      type: 'text/plain',
      listDepth: 0,
      value: null,
    },
  },
  outputs: {
    // This variable is dynamically updated in frontend based on `value`
    output: {
      key: 'output',
      name: 'Selected Item',
      type: 'object',
      listDepth: 0,
      isOptional: false,
      value: null,
    },
  },
} satisfies StepDefinition;
