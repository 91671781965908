import { default as TabSettingshRH2xkSQfZMeta } from "~/components/spell/tabs/TabSettings.vue?macro=true";
import { default as EditorYPmA3KJfqYMeta } from "~/pages/spell/Editor.vue?macro=true";
import { default as TemplateUUBfbNbztYMeta } from "~/pages/spell/Template.vue?macro=true";
import { default as TabAppRunxxqGziMX8QMeta } from "~/components/spell/tabs/TabAppRun.vue?macro=true";
import { default as TabAppBulkVPbyEk3R5bMeta } from "~/components/spell/tabs/TabAppBulk.vue?macro=true";
import { default as TabAppApiUbC9mC4D7xMeta } from "~/components/spell/tabs/TabAppApi.vue?macro=true";
import { default as TabAppHistorycpBWf4OJgdMeta } from "~/components/spell/tabs/TabAppHistory.vue?macro=true";
import { default as TabAppScheduleNfcgdjnU6yMeta } from "~/components/spell/tabs/TabAppSchedule.vue?macro=true";
import { default as TabAppSlackmqX0IRNXRcMeta } from "~/components/spell/tabs/TabAppSlack.vue?macro=true";
import { default as Spell3yRQ5nwW23Meta } from "~/pages/spell/Spell.vue?macro=true";
import { default as HomekkTrlWiSmGMeta } from "~/pages/dashboard/Home.vue?macro=true";
import { default as InboxCLdo9fqoi8Meta } from "~/pages/dashboard/Inbox.vue?macro=true";
import { default as Spells3RY0PkAPgDMeta } from "~/pages/dashboard/Spells.vue?macro=true";
import { default as DataSourcesadzfEW4PTzMeta } from "~/pages/dashboard/DataSources.vue?macro=true";
import { default as HistoryRvJxGM10pIMeta } from "~/pages/dashboard/History.vue?macro=true";
import { default as AgentsGr6bA442sKMeta } from "~/pages/dashboard/Agents.vue?macro=true";
import { default as ConfigO0xXTxLXU3Meta } from "~/pages/agents/tabs/Config.vue?macro=true";
import { default as Analytics41zHwCm2dpMeta } from "~/pages/agents/tabs/Analytics.vue?macro=true";
import { default as Campaigns2tNKV65mE7Meta } from "~/pages/agents/tabs/Campaigns.vue?macro=true";
import { default as AgentKhZi74KvctMeta } from "~/pages/agents/Agent.vue?macro=true";
import { default as Templatesw0u4QrV189Meta } from "~/pages/dashboard/Templates.vue?macro=true";
import { default as DocsPageboJiTgRhhsMeta } from "~/pages/dashboard/DocsPage.vue?macro=true";
import { default as Profilew00cYCHraeMeta } from "~/pages/account/Profile.vue?macro=true";
import { default as WorkspacesIyKE42RDj8Meta } from "~/pages/account/Workspaces.vue?macro=true";
import { default as LinkedAccountsmRtt0VSJ1UMeta } from "~/pages/account/LinkedAccounts.vue?macro=true";
import { default as BillingEIF0Y9qgWoMeta } from "~/pages/account/Billing.vue?macro=true";
import { default as SettingsUrC6AcKM0QMeta } from "~/pages/account/Settings.vue?macro=true";
import { default as AuthjO3fhsX2T4Meta } from "~/pages/auth/Auth.vue?macro=true";
import { default as InvitefiUCXhpWYLMeta } from "~/pages/Invite.vue?macro=true";
import { default as PrivacyPolicyTHTStAq9SwMeta } from "~/pages/marketing/PrivacyPolicy.vue?macro=true";
import { default as GooglePrivacyPolicyJqdNeWrFVKMeta } from "~/pages/marketing/GooglePrivacyPolicy.vue?macro=true";
import { default as TermsAndConditionspdhVCh7H1WMeta } from "~/pages/marketing/TermsAndConditions.vue?macro=true";
import { default as IntegrationsCisn9obyyDMeta } from "~/pages/Integrations.vue?macro=true";
import { default as nuxt_643_4611_462__64azure_43storage_45blob_6412_4617_460_encoding_640_461_4613___64parcel_43watcher_642_4641eNx2u1x7vMeta } from "/home/runner/work/respell/respell/node_modules/.pnpm/nuxt@3.11.2_@azure+storage-blob@12.17.0_encoding@0.1.13__@parcel+watcher@2.4.1_@types+node@20_lay3doh4nxhinpn7twpw3cuyt4/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as nuxt_643_4611_462__64azure_43storage_45blob_6412_4617_460_encoding_640_461_4613___64parcel_43watcher_642_4641eNx2u1x7v } from "/home/runner/work/respell/respell/node_modules/.pnpm/nuxt@3.11.2_@azure+storage-blob@12.17.0_encoding@0.1.13__@parcel+watcher@2.4.1_@types+node@20_lay3doh4nxhinpn7twpw3cuyt4/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: Spell3yRQ5nwW23Meta?.name ?? "spell",
    path: Spell3yRQ5nwW23Meta?.path ?? "/spell/:spellId",
    meta: Spell3yRQ5nwW23Meta || {},
    alias: Spell3yRQ5nwW23Meta?.alias || [],
    redirect: Spell3yRQ5nwW23Meta?.redirect,
    component: () => import("~/pages/spell/Spell.vue").then(m => m.default || m),
    children: [
  {
    name: TabSettingshRH2xkSQfZMeta?.name ?? "spell.settings",
    path: TabSettingshRH2xkSQfZMeta?.path ?? "settings",
    meta: TabSettingshRH2xkSQfZMeta || {},
    alias: TabSettingshRH2xkSQfZMeta?.alias || [],
    redirect: TabSettingshRH2xkSQfZMeta?.redirect,
    component: () => import("~/components/spell/tabs/TabSettings.vue").then(m => m.default || m)
  },
  {
    name: EditorYPmA3KJfqYMeta?.name ?? "editor",
    path: EditorYPmA3KJfqYMeta?.path ?? "edit",
    meta: EditorYPmA3KJfqYMeta || {},
    alias: EditorYPmA3KJfqYMeta?.alias || [],
    redirect: EditorYPmA3KJfqYMeta?.redirect,
    component: () => import("~/pages/spell/Editor.vue").then(m => m.default || m)
  },
  {
    name: TemplateUUBfbNbztYMeta?.name ?? "preview",
    path: TemplateUUBfbNbztYMeta?.path ?? "preview",
    meta: TemplateUUBfbNbztYMeta || {},
    alias: TemplateUUBfbNbztYMeta?.alias || [],
    redirect: TemplateUUBfbNbztYMeta?.redirect,
    component: () => import("~/pages/spell/Template.vue").then(m => m.default || m)
  },
  {
    name: TabAppRunxxqGziMX8QMeta?.name ?? "spell.run",
    path: TabAppRunxxqGziMX8QMeta?.path ?? "run",
    meta: TabAppRunxxqGziMX8QMeta || {},
    alias: TabAppRunxxqGziMX8QMeta?.alias || [],
    redirect: TabAppRunxxqGziMX8QMeta?.redirect,
    component: () => import("~/components/spell/tabs/TabAppRun.vue").then(m => m.default || m)
  },
  {
    name: TabAppBulkVPbyEk3R5bMeta?.name ?? "spell.bulk",
    path: TabAppBulkVPbyEk3R5bMeta?.path ?? "bulk/:groupId?/:runId?",
    meta: TabAppBulkVPbyEk3R5bMeta || {},
    alias: TabAppBulkVPbyEk3R5bMeta?.alias || [],
    redirect: TabAppBulkVPbyEk3R5bMeta?.redirect,
    component: () => import("~/components/spell/tabs/TabAppBulk.vue").then(m => m.default || m)
  },
  {
    name: TabAppApiUbC9mC4D7xMeta?.name ?? "spell.api",
    path: TabAppApiUbC9mC4D7xMeta?.path ?? "api",
    meta: TabAppApiUbC9mC4D7xMeta || {},
    alias: TabAppApiUbC9mC4D7xMeta?.alias || [],
    redirect: TabAppApiUbC9mC4D7xMeta?.redirect,
    component: () => import("~/components/spell/tabs/TabAppApi.vue").then(m => m.default || m)
  },
  {
    name: TabAppHistorycpBWf4OJgdMeta?.name ?? "spell.history",
    path: TabAppHistorycpBWf4OJgdMeta?.path ?? "history/:runId?",
    meta: TabAppHistorycpBWf4OJgdMeta || {},
    alias: TabAppHistorycpBWf4OJgdMeta?.alias || [],
    redirect: TabAppHistorycpBWf4OJgdMeta?.redirect,
    component: () => import("~/components/spell/tabs/TabAppHistory.vue").then(m => m.default || m)
  },
  {
    name: TabAppScheduleNfcgdjnU6yMeta?.name ?? "spell.schedule",
    path: TabAppScheduleNfcgdjnU6yMeta?.path ?? "schedule",
    meta: TabAppScheduleNfcgdjnU6yMeta || {},
    alias: TabAppScheduleNfcgdjnU6yMeta?.alias || [],
    redirect: TabAppScheduleNfcgdjnU6yMeta?.redirect,
    component: () => import("~/components/spell/tabs/TabAppSchedule.vue").then(m => m.default || m)
  },
  {
    name: TabAppSlackmqX0IRNXRcMeta?.name ?? "spell.slack",
    path: TabAppSlackmqX0IRNXRcMeta?.path ?? "slack",
    meta: TabAppSlackmqX0IRNXRcMeta || {},
    alias: TabAppSlackmqX0IRNXRcMeta?.alias || [],
    redirect: TabAppSlackmqX0IRNXRcMeta?.redirect,
    component: () => import("~/components/spell/tabs/TabAppSlack.vue").then(m => m.default || m)
  }
]
  },
  {
    name: HomekkTrlWiSmGMeta?.name ?? "dashboard",
    path: HomekkTrlWiSmGMeta?.path ?? "/:runId?",
    meta: HomekkTrlWiSmGMeta || {},
    alias: HomekkTrlWiSmGMeta?.alias || [],
    redirect: HomekkTrlWiSmGMeta?.redirect,
    component: () => import("~/pages/dashboard/Home.vue").then(m => m.default || m)
  },
  {
    name: InboxCLdo9fqoi8Meta?.name ?? "inbox",
    path: InboxCLdo9fqoi8Meta?.path ?? "/inbox/:tabId?",
    meta: InboxCLdo9fqoi8Meta || {},
    alias: InboxCLdo9fqoi8Meta?.alias || [],
    redirect: InboxCLdo9fqoi8Meta?.redirect,
    component: () => import("~/pages/dashboard/Inbox.vue").then(m => m.default || m)
  },
  {
    name: Spells3RY0PkAPgDMeta?.name ?? "spells",
    path: Spells3RY0PkAPgDMeta?.path ?? "/spells/:tab?",
    meta: Spells3RY0PkAPgDMeta || {},
    alias: Spells3RY0PkAPgDMeta?.alias || [],
    redirect: Spells3RY0PkAPgDMeta?.redirect,
    component: () => import("~/pages/dashboard/Spells.vue").then(m => m.default || m)
  },
  {
    name: Spells3RY0PkAPgDMeta?.name ?? "team",
    path: Spells3RY0PkAPgDMeta?.path ?? "/teams/:teamId/spells",
    meta: Spells3RY0PkAPgDMeta || {},
    alias: Spells3RY0PkAPgDMeta?.alias || [],
    redirect: Spells3RY0PkAPgDMeta?.redirect,
    component: () => import("~/pages/dashboard/Spells.vue").then(m => m.default || m)
  },
  {
    name: DataSourcesadzfEW4PTzMeta?.name ?? "dataSources",
    path: DataSourcesadzfEW4PTzMeta?.path ?? "/data-sources/:dataSourceId?",
    meta: DataSourcesadzfEW4PTzMeta || {},
    alias: DataSourcesadzfEW4PTzMeta?.alias || [],
    redirect: DataSourcesadzfEW4PTzMeta?.redirect,
    component: () => import("~/pages/dashboard/DataSources.vue").then(m => m.default || m)
  },
  {
    name: HistoryRvJxGM10pIMeta?.name ?? "history",
    path: HistoryRvJxGM10pIMeta?.path ?? "/history/:runId?",
    meta: HistoryRvJxGM10pIMeta || {},
    alias: HistoryRvJxGM10pIMeta?.alias || [],
    redirect: HistoryRvJxGM10pIMeta?.redirect,
    component: () => import("~/pages/dashboard/History.vue").then(m => m.default || m)
  },
  {
    name: AgentsGr6bA442sKMeta?.name ?? "agents",
    path: AgentsGr6bA442sKMeta?.path ?? "/agents",
    meta: AgentsGr6bA442sKMeta || {},
    alias: AgentsGr6bA442sKMeta?.alias || [],
    redirect: AgentsGr6bA442sKMeta?.redirect,
    component: () => import("~/pages/dashboard/Agents.vue").then(m => m.default || m)
  },
  {
    name: AgentKhZi74KvctMeta?.name ?? "agent",
    path: AgentKhZi74KvctMeta?.path ?? "/agent/:agentId",
    meta: AgentKhZi74KvctMeta || {},
    alias: AgentKhZi74KvctMeta?.alias || [],
    redirect: AgentKhZi74KvctMeta?.redirect,
    component: () => import("~/pages/agents/Agent.vue").then(m => m.default || m),
    children: [
  {
    name: ConfigO0xXTxLXU3Meta?.name ?? "agent.config",
    path: ConfigO0xXTxLXU3Meta?.path ?? "configure",
    meta: ConfigO0xXTxLXU3Meta || {},
    alias: ConfigO0xXTxLXU3Meta?.alias || [],
    redirect: ConfigO0xXTxLXU3Meta?.redirect,
    component: () => import("~/pages/agents/tabs/Config.vue").then(m => m.default || m)
  },
  {
    name: Analytics41zHwCm2dpMeta?.name ?? "agent.analytics",
    path: Analytics41zHwCm2dpMeta?.path ?? "analytics/:campaignId?",
    meta: Analytics41zHwCm2dpMeta || {},
    alias: Analytics41zHwCm2dpMeta?.alias || [],
    redirect: Analytics41zHwCm2dpMeta?.redirect,
    component: () => import("~/pages/agents/tabs/Analytics.vue").then(m => m.default || m)
  },
  {
    name: Campaigns2tNKV65mE7Meta?.name ?? "agent.campaigns",
    path: Campaigns2tNKV65mE7Meta?.path ?? "campaigns",
    meta: Campaigns2tNKV65mE7Meta || {},
    alias: Campaigns2tNKV65mE7Meta?.alias || [],
    redirect: Campaigns2tNKV65mE7Meta?.redirect,
    component: () => import("~/pages/agents/tabs/Campaigns.vue").then(m => m.default || m)
  }
]
  },
  {
    name: Templatesw0u4QrV189Meta?.name ?? "templates",
    path: Templatesw0u4QrV189Meta?.path ?? "/templates/:category?",
    meta: Templatesw0u4QrV189Meta || {},
    alias: Templatesw0u4QrV189Meta?.alias || [],
    redirect: Templatesw0u4QrV189Meta?.redirect,
    component: () => import("~/pages/dashboard/Templates.vue").then(m => m.default || m)
  },
  {
    name: DocsPageboJiTgRhhsMeta?.name ?? "docs",
    path: DocsPageboJiTgRhhsMeta?.path ?? "/docs",
    meta: DocsPageboJiTgRhhsMeta || {},
    alias: DocsPageboJiTgRhhsMeta?.alias || [],
    redirect: DocsPageboJiTgRhhsMeta?.redirect,
    component: () => import("~/pages/dashboard/DocsPage.vue").then(m => m.default || m)
  },
  {
    name: Profilew00cYCHraeMeta?.name ?? "profile",
    path: Profilew00cYCHraeMeta?.path ?? "/account/profile",
    meta: Profilew00cYCHraeMeta || {},
    alias: Profilew00cYCHraeMeta?.alias || [],
    redirect: Profilew00cYCHraeMeta?.redirect,
    component: () => import("~/pages/account/Profile.vue").then(m => m.default || m)
  },
  {
    name: WorkspacesIyKE42RDj8Meta?.name ?? "members",
    path: WorkspacesIyKE42RDj8Meta?.path ?? "/account/members",
    meta: WorkspacesIyKE42RDj8Meta || {},
    alias: WorkspacesIyKE42RDj8Meta?.alias || [],
    redirect: WorkspacesIyKE42RDj8Meta?.redirect,
    component: () => import("~/pages/account/Workspaces.vue").then(m => m.default || m)
  },
  {
    name: LinkedAccountsmRtt0VSJ1UMeta?.name ?? "linkedAccounts",
    path: LinkedAccountsmRtt0VSJ1UMeta?.path ?? "/account/linked-accounts",
    meta: LinkedAccountsmRtt0VSJ1UMeta || {},
    alias: LinkedAccountsmRtt0VSJ1UMeta?.alias || [],
    redirect: LinkedAccountsmRtt0VSJ1UMeta?.redirect,
    component: () => import("~/pages/account/LinkedAccounts.vue").then(m => m.default || m)
  },
  {
    name: BillingEIF0Y9qgWoMeta?.name ?? "billing",
    path: BillingEIF0Y9qgWoMeta?.path ?? "/account/billing",
    meta: BillingEIF0Y9qgWoMeta || {},
    alias: BillingEIF0Y9qgWoMeta?.alias || [],
    redirect: BillingEIF0Y9qgWoMeta?.redirect,
    component: () => import("~/pages/account/Billing.vue").then(m => m.default || m)
  },
  {
    name: SettingsUrC6AcKM0QMeta?.name ?? "settings",
    path: SettingsUrC6AcKM0QMeta?.path ?? "/account/settings",
    meta: SettingsUrC6AcKM0QMeta || {},
    alias: SettingsUrC6AcKM0QMeta?.alias || [],
    redirect: SettingsUrC6AcKM0QMeta?.redirect,
    component: () => import("~/pages/account/Settings.vue").then(m => m.default || m)
  },
  {
    name: AuthjO3fhsX2T4Meta?.name ?? "login",
    path: AuthjO3fhsX2T4Meta?.path ?? "/login",
    meta: AuthjO3fhsX2T4Meta || {},
    alias: AuthjO3fhsX2T4Meta?.alias || [],
    redirect: AuthjO3fhsX2T4Meta?.redirect,
    component: () => import("~/pages/auth/Auth.vue").then(m => m.default || m)
  },
  {
    name: AuthjO3fhsX2T4Meta?.name ?? "register",
    path: AuthjO3fhsX2T4Meta?.path ?? "/register",
    meta: AuthjO3fhsX2T4Meta || {},
    alias: AuthjO3fhsX2T4Meta?.alias || [],
    redirect: AuthjO3fhsX2T4Meta?.redirect,
    component: () => import("~/pages/auth/Auth.vue").then(m => m.default || m)
  },
  {
    name: InvitefiUCXhpWYLMeta?.name ?? "invite",
    path: InvitefiUCXhpWYLMeta?.path ?? "/invites/:inviteId",
    meta: InvitefiUCXhpWYLMeta || {},
    alias: InvitefiUCXhpWYLMeta?.alias || [],
    redirect: InvitefiUCXhpWYLMeta?.redirect,
    component: () => import("~/pages/Invite.vue").then(m => m.default || m)
  },
  {
    name: PrivacyPolicyTHTStAq9SwMeta?.name ?? "privacy",
    path: PrivacyPolicyTHTStAq9SwMeta?.path ?? "/privacy",
    meta: PrivacyPolicyTHTStAq9SwMeta || {},
    alias: PrivacyPolicyTHTStAq9SwMeta?.alias || [],
    redirect: PrivacyPolicyTHTStAq9SwMeta?.redirect,
    component: () => import("~/pages/marketing/PrivacyPolicy.vue").then(m => m.default || m)
  },
  {
    name: GooglePrivacyPolicyJqdNeWrFVKMeta?.name ?? "googlePrivacy",
    path: GooglePrivacyPolicyJqdNeWrFVKMeta?.path ?? "/google-privacy",
    meta: GooglePrivacyPolicyJqdNeWrFVKMeta || {},
    alias: GooglePrivacyPolicyJqdNeWrFVKMeta?.alias || [],
    redirect: GooglePrivacyPolicyJqdNeWrFVKMeta?.redirect,
    component: () => import("~/pages/marketing/GooglePrivacyPolicy.vue").then(m => m.default || m)
  },
  {
    name: TermsAndConditionspdhVCh7H1WMeta?.name ?? "terms",
    path: TermsAndConditionspdhVCh7H1WMeta?.path ?? "/terms",
    meta: TermsAndConditionspdhVCh7H1WMeta || {},
    alias: TermsAndConditionspdhVCh7H1WMeta?.alias || [],
    redirect: TermsAndConditionspdhVCh7H1WMeta?.redirect,
    component: () => import("~/pages/marketing/TermsAndConditions.vue").then(m => m.default || m)
  },
  {
    name: IntegrationsCisn9obyyDMeta?.name ?? "integrations",
    path: IntegrationsCisn9obyyDMeta?.path ?? "/apps/:primary?/:secondary?",
    meta: IntegrationsCisn9obyyDMeta || {},
    alias: IntegrationsCisn9obyyDMeta?.alias || [],
    redirect: IntegrationsCisn9obyyDMeta?.redirect,
    component: () => import("~/pages/Integrations.vue").then(m => m.default || m)
  },
  {
    name: nuxt_643_4611_462__64azure_43storage_45blob_6412_4617_460_encoding_640_461_4613___64parcel_43watcher_642_4641eNx2u1x7vMeta?.name ?? undefined,
    path: nuxt_643_4611_462__64azure_43storage_45blob_6412_4617_460_encoding_640_461_4613___64parcel_43watcher_642_4641eNx2u1x7vMeta?.path ?? "/spells",
    meta: nuxt_643_4611_462__64azure_43storage_45blob_6412_4617_460_encoding_640_461_4613___64parcel_43watcher_642_4641eNx2u1x7vMeta || {},
    alias: nuxt_643_4611_462__64azure_43storage_45blob_6412_4617_460_encoding_640_461_4613___64parcel_43watcher_642_4641eNx2u1x7vMeta?.alias || [],
    redirect: nuxt_643_4611_462__64azure_43storage_45blob_6412_4617_460_encoding_640_461_4613___64parcel_43watcher_642_4641eNx2u1x7vMeta?.redirect,
    component: nuxt_643_4611_462__64azure_43storage_45blob_6412_4617_460_encoding_640_461_4613___64parcel_43watcher_642_4641eNx2u1x7v
  }
]